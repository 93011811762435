import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import marked from "marked"
import PageHeadingTitle from "@components/pageHeadingTitle"

class SubProductTemplate extends React.Component {
  render() {
    const { subproduct } = this.props.data
    return (
      <Layout>
        <SEO title={subproduct.subtitle ? subproduct.subtitle : subproduct.title} description={subproduct.excerpt} />
        <div className="page-headline">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <PageHeadingTitle h1={"Information Center SubProduct"} h2={subproduct.title}/>
                {/* <span className="small">Posted on {post.publishedAt}</span> */}
              </div>
            </div>
          </div>
        </div>

        <div className="page-body">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                {subproduct.mainImage !== null ? (
                  <div className="text-center">
                    {/* <h2>{brand.title}</h2> */}
                    <img
                      src={subproduct.mainImage.asset.fluid.src}
                      alt="Subproduct image"
                      className="img-fluid mb-4"
                    />
                  </div>
                ) : null}

                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(subproduct.body || ""),
                  }}
                />

                <div className="row">
                  {subproduct.products.map(subproduct => (
                    <div className="col-md-4 mb-5" key={subproduct.id}>
                      <div className="blog-item bg-light rounded border">
                        <Link to={`/${subproduct.slug.current}`}>
                          <div className="blog-img-tile">
                            <div className="tile-bg-wrapper">
                              <span className="doc-icon">
                                <i className="fa fa-file-text"></i>
                              </span>
                              <div
                                className="tile-bg rounded-top"
                                style={{
                                  backgroundImage: `url(${subproduct.mainImage &&
                                    subproduct.mainImage.asset &&
                                    subproduct.mainImage.asset.fluid &&
                                    subproduct.mainImage.asset.fluid.src})`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </Link>
                        <div className="my-4 px-3">
                          <Link
                            to={`/${subproduct.slug.current}`}
                            className="h4"
                          >
                            {subproduct.title}
                          </Link>
                        </div>
                        <Link
                          to={`/${subproduct.slug.current}`}
                          className="btn btn-secondary btn-secondary-red btn-sm m-3"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SubProductTemplate

export const brandQuery = graphql`
  query SubProductTemplateQuery($id: String!) {
    subproduct: sanitySubProduct(id: { eq: $id }) {
      id
      excerpt
      title
      mainImage {
        asset {
          fluid {
            src
          }
        }
      }
      body
      products {
        id
        title
        slug {
          current
        }
        mainImage {
          asset {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
